@use '@/scss' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(16px);

  h6 {
    align-self: center;
  }

  p {
    text-align: center;
  }
}

.sections {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(8px);
}

.section {
  background-color: var(--colors-background-default);
  display: flex;
  padding: px-to-rem(8px) px-to-rem(16px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: px-to-rem(12px);
}

.buttons {
  gap: px-to-rem(8px);
  display: flex;
  flex-direction: column;
}
